"use strict";

document.addEventListener("DOMContentLoaded", function() {
    widgetPhone();

    try {
        Fancybox.bind("[data-fancybox].widget-phone", { // включать значок при закрытии попапа
            dragToClose: getComputedStyle(document.querySelector('.header__mobile')).display === 'flex' ? false : true, // bug: click to button misses on mobile
            on: {
                close: () => {
                    document.querySelector('.widget-phone').classList.add('active');
                },
            },
        });
    } catch (err) {
        console.log(err);
    }

});

function widgetPhone(){
    const popupTimer = 45000, // таймер, по истечении которого автоматически вызовется попап
        widgetTimer = 4000, // таймер, по истечении которого появится виджет
        cookieTimer = 1; // таймер действия куки (в сутках)

    let widgetPopupOpenFlag = false; // определяет, был ли открыт попап хотя бы 1 раз

    document.querySelector('.widgets-right').insertAdjacentHTML('afterbegin', `
    <div class="widget-phone-wrap">
        <button data-fancybox data-type="ajax" class="widget-phone compensate-for-scrollbar" data-src="/form_fmc/api/popap/?ext_key=eafb5f6a93f97492d06541dc5da00ee3&ext_var[name]=value&tpl_var=widget_phone" title="Обратный звонок">
            <span class="widget2-circles"><svg><use xlink:href="img/sprite.svg#phone"></use></svg></span>
        </button>
    </div>
    `);

    const widgetPhone = document.querySelector('.widget-phone');

    //показываем виджет через 5 сек после загрузки страницы
    setTimeout(function(){
        widgetPhone.classList.add('active');
    },widgetTimer);

    //клик по виджету
    widgetPhone.addEventListener('click', () => {
        clickWidgetPhonePopup(widgetPhone); //вызываем функцию
    });

    // Если попап еще ни разу не был открыт (нет куки widget_phone_popup_show)
    if( !Cookies.get('widget_phone_popup_show') ){
        setTimeout(function(){
            // И если попап не открывали вручную и в данный момент не открыто никакого другого попапа
            if (!widgetPopupOpenFlag && !document.documentElement.classList.contains('with-fancybox') ){
                // то смотри функцию
                clickWidgetPhonePopup(widgetPhone, 1);
            }
        },popupTimer);
    }

    //ф-я при вызове попапа виджета
    function clickWidgetPhonePopup(widgetPhone, no_click_to_btn){
        widgetPopupOpenFlag = true;

        // То открываем этот попап (спустя 45 сек нахождения на странице)
        // (опять же, если нет куки widget_phone_popup_show)
        // Проверяем куку для того, чтобы попап не открывался на каждой странице сайта, если было открыто несколько вкладок.
        if( !Cookies.get('widget_phone_popup_show') ){
            if(no_click_to_btn){ // если попап вызывается автоматически, а не кликом по кнопке, то скриптом кликаем по кнопке
                widgetPhone.click(); //вызываем попап
            }
        }
        widgetPhone.classList.remove('active');

        // и стаим куку на сутки, показывающую, что попап был открыт
        Cookies.set('widget_phone_popup_show', 'true', { expires: cookieTimer });
    }
}